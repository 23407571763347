<button
  type="button"
  class="icon-button fxflex fxcentercenter"
  [attr.data-cy]="dataCy"
  [matTooltip]="text | translate"
  [matTooltipPosition]="tooltipPosition"
  [disabled]="disabled"
  [class.fill]="fill"
  [class.border]="outline"
>
  <mat-icon [svgIcon]="icon"></mat-icon>
</button>
