<mat-label
  class="form-label"
  [class.warn]="control.invalid && !control.untouched"
  [attr.data-cy]="dataCy + '-label'"
>
  {{ label | translate }}
</mat-label>

<mat-form-field
  appearance="outline"
  class="border white-background radius-6 custom-arrow outline-dropdown panel-below full-width"
  [attr.data-cy]="dataCy + '-dropdown'"
>
  <mat-select
    #selectPanel
    panelClass="single-select-panel outline-dropdown-panel scrollable"
    data-cy="select"
    [placeholder]="placeholder | translate"
    [value]="selected"
    (closed)="closeDropdown()"
    (selectionChange)="select($event)"
  >
    @for (option of options; track option.id && option.name) {
      <mat-option data-cy="mat-option" [value]="option.id">
        @if (option.marked) {
          <div class="status-circle activated"></div>
        }
        <span
          #wrapper
          class="black-text ellipsis full-width"
          [matTooltip]="option.name | translate"
          [matTooltipDisabled]="wrapper.offsetWidth >= text.scrollWidth"
        >
          <div #wrapper class="fxflex">
            <span #text class="black-text trimmed">{{
              option.name | translate
            }}</span>
          </div>
        </span>
      </mat-option>
    }
  </mat-select>

  <mat-icon class="dropdown-icon" matSuffix svgIcon="chevron-up" />
</mat-form-field>
