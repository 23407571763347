import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SystemEffects } from './system.effects';
import { systemFeature } from './system.reducer';
import { SystemService } from './system.service';
import { SystemTrackingEffects } from './tracking/system-tracking.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(systemFeature),
    EffectsModule.forFeature([SystemEffects, SystemTrackingEffects]),
  ],
  providers: [SystemService],
})
export class SystemStoreModule {}
