import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from 'rio-models';

@Component({
  selector: 'app-single-select',
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
  ],
  templateUrl: './single-select.component.html',
  styleUrl: './single-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SingleSelectComponent,
      multi: true,
    },
  ],
})
export class SingleSelectComponent implements OnInit, ControlValueAccessor {
  cdr = inject(ChangeDetectorRef);
  injector = inject(Injector);

  @ViewChild('selectPanel') selectPanel: MatSelect;

  @Input({ required: true }) options: SelectOption[];
  @Input({ required: false }) label: string;
  @Input({ required: false }) placeholder: string;
  @Input({ required: false }) dataCy: string;

  selected: string;
  control: NgControl;

  ngOnInit() {
    this.control = this.injector.get(NgControl);
  }

  onTouched: () => void;
  onChanged: (value: string) => void;

  select(event: MatSelectChange) {
    event.source.close();
    const value = event.value;

    this.writeValue(value);
    this.onChanged(value);
    this.onTouched();
  }

  writeValue(value: string): void {
    if (!value) {
      return;
    }

    this.selected = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  closeDropdown() {
    this.onTouched();
    this.selectPanel._onBlur();
  }
}
