import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { routerActions } from '../router/router.actions';
import { userFeature } from '../user/user.reducer';
import { selectIsUserSuperAdminOrSupport } from '../user/user.selectors';
import { systemActions } from './system.actions';
import { SystemService } from './system.service';

@Injectable()
export class SystemEffects {
  headerLogoClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.headerLogoClicked),
      concatLatestFrom(() =>
        this.store.select(selectIsUserSuperAdminOrSupport),
      ),
      map(([{ openDialog }, isSuperAdminOrSupport]) => {
        const openSystemVersion = openDialog && isSuperAdminOrSupport;

        return openSystemVersion
          ? systemActions.getSystemVersions()
          : routerActions.navigateToDefaultRoute();
      }),
    ),
  );

  showVersionsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.getSystemVersions),
      switchMap(() =>
        this.systemService.getSystemVersions().pipe(
          map(versions => systemActions.getSystemVersionsSuccess({ versions })),
          catchError(() => of(systemActions.getSystemVersionsFailure())),
        ),
      ),
    ),
  );

  openHelpMenuItemLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(systemActions.openHelpMenuItemLink),
        concatLatestFrom(() => this.store.select(userFeature.selectUiLanguage)),
        tap(([{ item }, uiLanguage]) => {
          window.open(item.link[uiLanguage], '_blank');
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private systemService: SystemService,
  ) {}
}
