import {
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  filter,
  fromEvent,
  merge,
  mergeMap,
  Observable,
  OperatorFunction,
  takeUntil,
  tap,
} from 'rxjs';

@Directive({ selector: '[appAutoScrollToBottom]' })
export class AutoScrollToBottomDirective implements OnInit, OnChanges {
  destroyRef = inject(DestroyRef);
  element = inject(ElementRef);

  private generatedText$ = new BehaviorSubject<string>('');
  private isGenerating$ = new BehaviorSubject<boolean>(false);

  @Input({ required: true }) generatedText: string;
  @Input({ required: true }) isGenerating: boolean;

  click$ = fromEvent(document, 'click', { capture: true });
  scroll$ = fromEvent(document, 'wheel', { capture: true });
  stopAutoScrolling$ = merge(this.click$, this.scroll$);

  ngOnInit() {
    const scrollToBottom$ = this.generatedText$.pipe(this.scrollOperator());

    this.isGenerating$
      .pipe(this.isGeneratingOperator(scrollToBottom$))
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.generatedText) {
      this.generatedText$.next(this.generatedText);
    }

    if (changes.isGenerating) {
      this.isGenerating$.next(this.isGenerating);
    }
  }

  scrollOperator(): OperatorFunction<string, string> {
    return source =>
      source.pipe(
        tap(() => this.scrollToBottom()),
        takeUntil(this.stopAutoScrolling$),
      );
  }

  isGeneratingOperator(
    scrollToBottom$: Observable<string>,
  ): OperatorFunction<boolean, string> {
    return source =>
      source.pipe(
        filter(isGenerating => isGenerating),
        mergeMap(() => scrollToBottom$),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  scrollToBottom() {
    const top = this.element?.nativeElement?.scrollHeight;
    this.element?.nativeElement?.scrollTo(0, top);
  }
}
