import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SystemVersionEntry } from 'rio-models';
import { HelpMenuItem } from './models';

export const systemActions = createActionGroup({
  source: 'System',
  events: {
    headerLogoClicked: props<{ openDialog: boolean }>(),
    getSystemVersions: emptyProps(),
    getSystemVersionsSuccess: props<{
      versions: SystemVersionEntry[];
    }>(),
    getSystemVersionsFailure: emptyProps(),
    openHelpMenuItemLink: props<{ item: HelpMenuItem }>(),
  },
});
