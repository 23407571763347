import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { trackingActions, TrackingEvent } from 'rio-tracking';
import { map } from 'rxjs';
import { systemActions } from '../system.actions';
import { HelpMenuItemClickedPayload } from './models';

@Injectable()
export class SystemTrackingEffects {
  helpMenuItemClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.openHelpMenuItemLink),
      map(({ item: { id } }) => {
        const payload: HelpMenuItemClickedPayload = {
          id,
        };

        return trackingActions.track({
          trackingEvent: TrackingEvent.System.HelpMenuItemClicked,
          payload,
        });
      }),
    ),
  );

  constructor(private actions$: Actions) {}
}
