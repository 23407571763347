import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { FileUploader, Headers } from 'ng2-file-upload';
import { FileUploaderOptions } from 'ng2-file-upload/file-upload/file-uploader.class';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FileUploaderService {
  private keycloakService = inject(KeycloakService);

  createFileUploader(options: FileUploaderOptions): Observable<FileUploader> {
    return this.getHeadersForFileUploader(this.keycloakService).pipe(
      map(
        headers => new FileUploader({ headers, autoUpload: true, ...options }),
      ),
    );
  }

  getHeadersForFileUploader(
    keycloakService: KeycloakService,
  ): Observable<Headers[]> {
    return this.getContentHeaders(keycloakService).pipe(
      map(requestHeaders =>
        requestHeaders.keys().map((name: string) => {
          const values = requestHeaders?.getAll(name);

          return {
            name,
            value: values[0],
          };
        }),
      ),
    );
  }

  private getContentHeaders(
    keycloakService: KeycloakService,
  ): Observable<HttpHeaders> {
    const headers = new HttpHeaders({
      Accept: 'application/json;charset=utf-8',
    });

    return keycloakService.addTokenToHeader(headers);
  }
}
